<script setup>
import {
  ref, getCurrentInstance, onMounted,
} from 'vue';
import dayjs from 'dayjs';
import { appointStateList } from './data.js';

const { proxy } = getCurrentInstance();

const searchParams = {
  appointDayList: getTimeList(),
  shopId: '',
  appointState: '',
  goodsName: '',
  appointMobile: undefined,
  currentPage: 1,
  nowPageSize: 10,
  count: 0,
};
const params = ref({ ...searchParams });

const loading = ref(false);
const tableData = ref([]);
const shopList = ref([]);

function operation(type, row) {
  if (type === 'open_cancel') {
    proxy.$confirm('提交后，用户可以在预约记录中，取消预约', '确认打开取消预约入口？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      openSubmit(row);
    }).catch(() => {
    });
  } else {
    const path = '/supplyChain/cooperation/detail';
    proxy.$router.push({
      path,
      query: {
        type,
        id: row?.id,
      },
    });
  }
}
async function openSubmit({ id }) {
  const res = await proxy.$axios.post(proxy.$api.userExchangeCard.open_cancel, {
    id,
  });
  if (res.code !== 0) return;
  proxy.$message.success('操作成功！');
  getList();
}
// 分页控制
function handleSizeChange(val) {
  params.value.nowPageSize = val;
  getList(1);
}
function handleCurrentChange(val) {
  params.value.currentPage = val;
  getList();
}
async function getList(num) {
  if (num) params.value.currentPage = num;
  loading.value = true;
  const [appointDayStart, appointDayEnd] = params.value.appointDayList || [];
  const postParams = {
    ...params.value,
    appointDayStart,
    appointDayEnd,
    currentPage: params.value.currentPage,
    pageSize: params.value.nowPageSize,
    nowPageSize: undefined,
    count: undefined,
  };
  delete postParams.appointDayList;
  const res = await proxy.$axios.post(proxy.$api.userExchangeCard.appoint_list, postParams);
  loading.value = false;
  if (res.code !== 0) return;
  tableData.value = res.data.records;
  params.value.count = Number(res.data.total);
}
async function getShopList() {
  const res = await proxy.$axios.get(proxy.$api.tradeShop.list);
  if (res.code !== 0) return;
  shopList.value = res.data;
}
function getTimeList() {
  const nowTime = new Date();
  const beforeTime = new Date();
  beforeTime.setDate(beforeTime.getDate() - 7);
  return [dayjs(beforeTime).format('YYYY-MM-DD'), dayjs(nowTime).format('YYYY-MM-DD')];
}
function changeTime(row) {
  if (!row) return;
  const times = 180 * 24 * 60 * 60 * 1000;
  if (dayjs(row[1]).valueOf() - dayjs(row[0]).valueOf() > times) {
    proxy.$message.error('最多选择180天');
    row[1] = dayjs(row[0]).add(10, 'day').format('YYYY-MM-DD');
  }
}
function timeFormat(time) {
  return time ? dayjs(+time).format('YYYY-MM-DD hh:mm:ss') : '--';
}
onMounted(() => {
  getList(1);
  getShopList();
});
</script>

<template>
  <div class="outer supply-chain">
    <search-panel
      show-btn
      flex
      @getList="getList(1)"
    >
      <div>
        <p>预约日期</p>
        <el-date-picker
          v-model="params.appointDayList"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="changeTime"
        />
      </div>
      <div>
        <p>
          门店
        </p>
        <el-select
          v-model="params.shopId"
          size="mini"
          placeholder="请选择门店"
          filterable
          clearable
        >
          <el-option
            v-for="(item, i) in shopList"
            :key="i"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div>
        <p>
          状态
        </p>
        <el-select
          v-model="params.appointState"
          size="mini"
          placeholder="请选择状态"
          filterable
          clearable
        >
          <el-option
            v-for="(item, i) in appointStateList"
            :key="i"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </div>
      <div>
        <p>商品名称</p>
        <el-input
          v-model="params.goodsName"
          clearable
          placeholder="请输入"
          size="mini"
        />
      </div>
      <div>
        <p>联系电话</p>
        <el-input-number
          v-model="params.appointMobile"
          class="w200"
          size="mini"
          :controls="false"
          :min="0"
          :max="19999999999"
          :precision="0"
          placeholder="请输入"
          clearable
        />
      </div>
    </search-panel>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      class="tableBox"
      element-loading-text="拼命加载中"
      style="width: 100%"
      row-key="relationGoodsId"
      :header-cell-style="{
        background: '#f5f7fa',
        color: '#333'
      }"
      size="medium"
    >
      <el-table-column
        prop="goodsName"
        label="商品名称"
      />
      <el-table-column
        prop="shopName"
        label="门店"
      />
      <el-table-column
        prop="appointDay"
        label="预约日期"
      />
      <el-table-column
        prop="appointPeriod"
        label="规格"
      />
      <el-table-column
        prop="appointName"
        label="联系人名"
      />
      <el-table-column
        prop="appointMobile"
        label="联系电话"
      />
      <el-table-column
        label="状态"
      >
        <template slot-scope="{row}">
          {{ (appointStateList.find(item => item.value === row.appointState))?.name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="提交时间"
      >
        <template slot-scope="{row}">
          {{ timeFormat(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="150"
      >
        <template
          slot-scope="{row}"
        >
          <el-button
            v-for="(item, i) in row.buttonList"
            :key="i"
            type="text"
            size="mini"
            @click="operation(item.code, row)"
          >
            {{ item.name }}
          </el-button>
          <span v-if="row.buttonList.length === 0">--</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData && tableData.length > 0"
      :current-page="params.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="params.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="params.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<style scoped lang="scss">
:deep(.el-input-number .el-input__inner) {
  text-align: left;
}
</style>
